import { z } from 'zod';

import { uuidSchema, einSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

const payoutRelatedClaimSchema = z
  .object({
    id: uuidSchema,
    type: z.literal('wallet'),
    walletAddress: addressSchema,
  })
  .or(
    z.object({
      id: uuidSchema,
      type: z.literal('wire'),
    }),
  );
export type PayoutRelatedClaim = z.infer<typeof payoutRelatedClaimSchema>;

const payoutTypeSchema = z.enum(['wallet', 'wire']);
const payoutRecipientSchema = z.object({
  recipientType: payoutTypeSchema,
  recipientAddress: addressSchema,
});
export type PayoutRecipient = z.infer<typeof payoutRecipientSchema>;

const payoutRelatedOrgSchema = z.object({
  orgName: z.string(),
  orgId: uuidSchema,
  ein: einSchema,
  isClaimed: z.boolean(),
});
export type PayoutRelatedOrg = z.infer<typeof payoutRelatedOrgSchema>;

export const pendingPayoutSchema = z.object({
  id: uuidSchema,
  ein: einSchema,
  name: z.string(),
  /** A USDC value */
  orgBalance: bigIntSchema,
  contractAddress: addressSchema,
  /** A USD value in cents */
  lifetimeContributions: bigIntSchema,
  claim: payoutRelatedClaimSchema.nullish(),
  compliant: z.boolean(),
  nonComplianceReasons: z.array(z.string()).nullish(),
});
export type PendingPayout = z.infer<typeof pendingPayoutSchema>;

export const finalizedPayoutSchema = z.object({
  id: uuidSchema,
  payoutType: payoutTypeSchema,
  claimId: uuidSchema,
  recipient: payoutRecipientSchema,
  org: payoutRelatedOrgSchema,
  /** A USDC value */
  netAmount: bigIntSchema,
  /** A USDC value */
  fee: bigIntSchema,
  createdAt: z.string(),
  finalizedAt: z.string(),
  transactionHash: addressSchema,
  logIndex: z.number(),
  circlePayoutId: uuidSchema.nullish(),
  payoutStatus: z.enum(['Completed', 'Failed', 'PendingWire']),
});
export type FinalizedPayout = z.infer<typeof finalizedPayoutSchema>;
