import type { SetStateAction } from 'jotai';
import { atom } from 'jotai';
import SuperJSON from 'superjson';

type Serializable = bigint | boolean | number | object | string;

export const localStorageAtom = <Value extends Serializable>(key: string, defaultValue: Value) => {
  const getInitialValue = () => {
    if (typeof window === 'undefined') return defaultValue;

    const value = localStorage.getItem(key);
    if (value) {
      return SuperJSON.parse(value) as Value;
    }
    return defaultValue;
  };
  const baseAtom = atom(getInitialValue());
  return atom(
    (get): Value => get(baseAtom),
    (get, set, update: SetStateAction<Value>) => {
      const updateValue = (value: Value) => {
        if (typeof window === 'undefined') setTimeout(() => updateValue(value), 1000);

        try {
          localStorage.setItem(key, SuperJSON.stringify(value));
          set(baseAtom, value);
        } catch (e) {
          console.error(e);
          throw e;
        }
      };

      const prev = get(baseAtom);
      updateValue(typeof update == 'function' ? update(prev) : update);
    },
  );
};
