import { TrackingHeadScript } from '@phntms/next-gtm';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactElement } from 'react';

import { featureFlags } from '@endaoment-frontend/config';
import { convertDehydratedStringToState, defaultQueryClient } from '@endaoment-frontend/data-fetching';

/** This component is used to wrap all providers for both the app and unit testing */
export const AppProvider = ({
  dehydratedState,
  children,
}: {
  dehydratedState?: string;
  children: Array<ReactElement> | ReactElement;
}) => {
  return (
    <QueryClientProvider client={defaultQueryClient}>
      {/* Uses the NEXT_PUBLIC_GA_MEASUREMENT_ID env variable */}
      <TrackingHeadScript id={featureFlags.gaMeasurementId} isGTM />
      <HydrationBoundary state={convertDehydratedStringToState(dehydratedState)} />
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
