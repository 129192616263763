import { forwardRef } from 'react';

import type { IconProps } from './Icon.types';

export const InfoIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ width = 22, height, color = '#EA6B0E', ...props }, ref) => (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM12.5 6.5C12.5 7.32843 11.8284 8 11 8C10.1716 8 9.5 7.32843 9.5 6.5C9.5 5.67157 10.1716 5 11 5C11.8284 5 12.5 5.67157 12.5 6.5ZM12.5 11V16C12.5 16.8284 11.8284 17.5 11 17.5C10.1716 17.5 9.5 16.8284 9.5 16V11C9.5 10.1716 10.1716 9.5 11 9.5C11.8284 9.5 12.5 10.1716 12.5 11Z'
        fill={color}
        fillOpacity='0.71'
      />
    </svg>
  ),
);
