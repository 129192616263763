import type { IconProps } from '../Icon.types';

export const ReceiptIcon = ({ width = 36, height = 36, color, className, ...props }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24 4H12C8.68629 4 6 6.68629 6 10V25C6 28.3137 8.68629 31 12 31H24C27.3137 31 30 28.3137 30 25V10C30 6.68629 27.3137 4 24 4Z'
        fill='white'
      />
      <path
        d='M12 4.75H24C26.8995 4.75 29.25 7.10051 29.25 10V25C29.25 27.8995 26.8995 30.25 24 30.25H12C9.10051 30.25 6.75 27.8995 6.75 25V10C6.75 7.1005 9.10051 4.75 12 4.75Z'
        stroke={color ?? '#627EEA'}
        strokeOpacity='0.75'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.5' d='M12 11H16' stroke={color ?? '#627EEA'} strokeWidth='1.75' strokeLinecap='round' />
      <path opacity='0.5' d='M12 15.333H16' stroke={color ?? '#627EEA'} strokeWidth='1.75' strokeLinecap='round' />
      <path opacity='0.5' d='M12 19.667H24' stroke={color ?? '#627EEA'} strokeWidth='1.75' strokeLinecap='round' />
      <path opacity='0.5' d='M12 24H24' stroke={color ?? '#627EEA'} strokeWidth='1.75' strokeLinecap='round' />
      <path
        d='M19 15L25 9M25 9H19M25 9V15'
        stroke={color ?? '#627EEA'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReceiptIcon;
