import { useMedia } from 'react-use';

const MEDIA_QUERY_MOBILE_BREAKPOINT = '(max-width: 71rem)';

type UseIsMobileOptions = {
  defaultState?: boolean;
};

export const getIsMobile = (): boolean => {
  if (typeof window === 'undefined') return false;
  return window.matchMedia(MEDIA_QUERY_MOBILE_BREAKPOINT).matches;
};

export const useIsMobile = (options?: UseIsMobileOptions): { isMobile: boolean } => {
  const { defaultState }: Required<UseIsMobileOptions> = { defaultState: false, ...options };
  const isMobile = useMedia(MEDIA_QUERY_MOBILE_BREAKPOINT, defaultState);

  return { isMobile };
};
