import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { useMount } from 'react-use';

import { config } from '@endaoment-frontend/config';
import { INTERCOM_APP_ID } from '@endaoment-frontend/constants';
import { useIsMounted } from '@endaoment-frontend/hooks';

type IntercomCustomAttribute = boolean | number | string | null | undefined;

type IntercomCompany = {
  name: string;
  id?: number | string | undefined;
  company_id?: number | string | undefined;
  created_at?: number | undefined;
  remote_created_at?: number | undefined;
  plan?: string | undefined;
  monthly_spend?: number | undefined;
  user_count?: number | undefined;
  size?: number | undefined;
  website?: string | undefined;
  industry?: string | undefined;
  [custom_attribute: string]: IntercomCustomAttribute;
};

type IntercomAvatar = {
  type: 'avatar';
  image_url: string;
};

type IntercomSettings = {
  // Messenger attributes
  app_id?: string | undefined;
  api_base?: string | undefined;

  // Data attributes
  email?: string | undefined;
  phone?: string | undefined;
  name?: string | undefined;
  user_id?: string | undefined;
  user_hash?: string | undefined;

  // Custom attributes
  [custom_attribute: string]: Array<IntercomCompany> | IntercomAvatar | IntercomCompany | IntercomCustomAttribute;
};

type IntercomFunction = ((
  event: 'boot' | 'reattach_activator' | 'shutdown' | 'update',
  settings?: IntercomSettings,
) => void) & {
  q?: Array<unknown>;
};

declare global {
  interface Window {
    intercomSettings?: IntercomSettings;
    Intercom?: IntercomFunction;
    attachEvent?: (event: string, listener: EventListener) => void;
  }
}

const IntercomScript = () => {
  useMount(() => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('reattach_activator');
      window.Intercom('update', window.intercomSettings);
      return;
    }

    const intercomFunc: IntercomFunction = (event, settings) => {
      if (!intercomFunc.q) intercomFunc.q = [];
      window.intercomSettings = { ...window.intercomSettings, ...settings };
      intercomFunc.q.push([event, settings]);
    };
    window.Intercom = intercomFunc;
  });

  const router = useRouter();
  useEffect(() => {
    const updateIntercom = () => {
      if (typeof window === 'undefined' || !window.Intercom) return;
      window.Intercom('update', {
        app_id: INTERCOM_APP_ID,
      });
    };
    router.events.on('routeChangeComplete', updateIntercom);
    return () => {
      router.events.off('routeChangeComplete', updateIntercom);
    };
  }, [router.events]);

  const bootIntercom: (() => void) & {
    attempts?: number;
  } = () => {
    if (!bootIntercom.attempts) bootIntercom.attempts = 0;
    if (typeof window === 'undefined' || bootIntercom.attempts > 3) return;
    bootIntercom.attempts++;
    if (!window.Intercom) {
      setTimeout(bootIntercom, 1000);
      return;
    }

    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
    });
  };
  return (
    <Script
      type='text/javascript'
      async
      src={`https://widget.intercom.io/widget/${INTERCOM_APP_ID}`}
      onReady={bootIntercom}
    />
  );
};

export const IntercomLoader = () => {
  const isMounted = useIsMounted();

  // Don't load Intercom in local development, it isn't a trusted domain
  const isLocal = config.environmentName === 'local';

  if (!isMounted || isLocal) return <></>;
  return <IntercomScript />;
};
