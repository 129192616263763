import { forwardRef } from 'react';

import type { IconProps } from './Icon.types';

export const CopyIcon = forwardRef<SVGSVGElement, IconProps>(function Copy(
  { width = 24, height, strokeColor = '#53ACDE', strokeWidth = 2, color, ...props },
  ref,
) {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}>
      <path
        d='M20.9 13.2857H17.1C15.4431 13.2857 14.1 14.6289 14.1 16.2857V22.7143M20.9 13.2857H23C24.6569 13.2857 26 14.6289 26 16.2857V24C26 25.6569 24.6569 27 23 27H17.1C15.4431 27 14.1 25.6569 14.1 24V22.7143M20.9 13.2857V11.5714C20.9 10.1513 19.7583 9 18.35 9H11.55C10.1417 9 9 10.1513 9 11.5714V20.1429C9 21.563 10.1417 22.7143 11.55 22.7143H14.1'
        // TODO: fix references so this doesn't result in a bunch of black icons
        // stroke={color ?? strokeColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});
