// eslint-disable-next-line no-restricted-imports
import parse from 'html-react-parser';
import { sanitize } from 'isomorphic-dompurify';

type ParseHtmlOptions = {
  forbidLinks?: boolean;
};

// This needs to be in its own file because it breaks all UI tests
// There's a bug with TextEncoder / TextDecoder in jsdom
export const parseHtml = (dirtyHtml?: string, options?: ParseHtmlOptions) => {
  const forbiddenTags: Array<string> = [];
  if (options?.forbidLinks) {
    forbiddenTags.push('a');
  }

  return parse(
    sanitize(dirtyHtml || '', { ADD_TAGS: ['img'], ADD_ATTR: ['src', 'alt', 'style'], FORBID_TAGS: forbiddenTags }),
  );
};
