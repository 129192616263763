import type { ImageProps } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';
import type { ReactNode } from 'react';

import styles from './Avatar.module.scss';

type AvatarProps = {
  src?: ImageProps['src'];
  type?: 'circle' | 'square';
  children?: ReactNode;
  className?: string;
};

export const Avatar = ({ type = 'circle', src, children, className }: AvatarProps) => {
  return (
    <div className={clsx(styles['avatar'], className, type === 'square' && styles['avatar--square'])}>
      {/* TODO: define sizes and placeholder */}
      {!!src && <Image src={src} alt='' width='80' height='80' loading='eager' />}
      {!src && children}
    </div>
  );
};
