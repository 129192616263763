import { useId } from 'react';

import type { IconProps } from './Icon.types';

export const CompassIcon = ({ color, width = 22, height, ...props }: IconProps) => {
  const id = useId();
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx='11' cy='11' r='10' fill='white' stroke='#53ACDE' strokeWidth='1.5' />
        <path
          d='M13.2338 12.4516L15.5777 7.76371C16.0075 6.90415 15.0957 5.99229 14.2361 6.42207L9.54822 8.76601C9.20954 8.93535 8.93493 9.20996 8.76559 9.54864L6.42165 14.2365C5.99187 15.0961 6.90373 16.0079 7.76329 15.5782L12.4512 13.2342C12.7899 13.0649 13.0645 12.7903 13.2338 12.4516Z'
          fill='#EA6B0E'
          fillOpacity='0.25'
          stroke='#EA6B0E'
          strokeWidth='1.5'
        />
        <circle cx='11' cy='11' r='1' fill='#EA6B0E' />
        <path d='M11 3.5L11 5.25' stroke='#E4EBF4' strokeWidth='1.5' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
