import type { ConfigEntry, DefaultsEntry } from './config.types';
import { config as devConfig } from './dev.config';
import { featureFlags } from './flags';
import * as generated from './generated';
import { testDefaults } from './testDefaults';

// Force the use of test defaults in test environments
const { config, defaults }: { config: ConfigEntry; defaults: DefaultsEntry } = featureFlags.isTestEnv
  ? { config: devConfig, defaults: testDefaults }
  : { config: generated.config, defaults: generated.defaults };

export type { App, Environment } from './config.types';
export { config, defaults, featureFlags };
