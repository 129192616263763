import { Link } from '@chakra-ui/next-js';

import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon, LogoTextIcon } from '@endaoment-frontend/ui/icons';
import { Button, SocialLinks } from '@endaoment-frontend/ui/shared';

import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles['footer-wrapper']}>
      <div className={styles['links-container']}>
        <div className={styles['links-logo']}>
          <LogoTextIcon color='currentColor' />
          <span>The Smartest Way to Give</span>
        </div>
        <div>
          <h6>Information</h6>
          <Link href={routes.marketing.about()}>About Endaoment</Link>
          <Link href={routes.marketing.learn()}>Learn More & FAQs</Link>
          <Link href={routes.developers.home()}>Developers</Link>
          <a href='https://docs.endaoment.org/governance' target='_blank' rel='noreferrer'>
            Legal & Governance
          </a>
        </div>
        <div>
          <h6>Endaoment App</h6>
          <a href={routes.app.home()} target='_blank' rel='noreferrer'>
            App Home
          </a>
          <a href={routes.app.explore()} target='_blank' rel='noreferrer'>
            Search Organizations
          </a>
        </div>
        <div>
          <h6>Donor Resources</h6>
          <Link href={routes.marketing.donors()}>Endaoment for Donors</Link>
          <Link href={routes.marketing.advisors()}>Endaoment For Financial Advisors</Link>
          <a href='https://docs.endaoment.org/donors' target='_blank' rel='noreferrer'>
            Documentation & Tutorials
          </a>
          <Link href={routes.marketing.otc()}>Over-the-Counter Donations</Link>
          <Link href={routes.marketing.donationCalculator()}>Donation Calculator</Link>
        </div>
        <div>
          <h6>Nonprofit Resources</h6>
          <Link href={routes.marketing.orgs()}>Endaoment for Nonprofits</Link>
          <a href='https://docs.endaoment.org/organizations' target='_blank' rel='noreferrer'>
            Documentation & Tutorials
          </a>
        </div>
      </div>
      <SocialLinks />
      <div className={styles['extra-container']}>
        <div>
          <p className={styles['ndao-ein']}>Endaoment is a registered United States 501(c)(3) with EIN 84-4661797</p>
          <span className={styles['ndao-copyright']}>© {new Date().getFullYear()} Endaoment</span>
        </div>
        <Button as='a' href={routes.app.home()} className={styles['extra-app-button']}>
          Launch App
          <ArrowIcon width={20} height={12} />
        </Button>
      </div>
    </footer>
  );
};
