import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import styles from './Card.module.scss';

declare module 'react' {
  /** Fixed Ref typings (They suck) */
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const cardClassNames = {
  base: styles['card'],
  removePadding: styles['card--no-padding'],
  removeShadow: styles['card--no-shadow'],
};

export type CardProps = ComponentPropsWithoutRef<'div'> & {
  children?: Array<ReactNode> | ReactNode;
  className?: string;
  noPadding?: boolean;
  noShadow?: boolean;
  mobileFullWidth?: boolean;
};

const CardWithRef = (
  { children, noPadding, noShadow, className, mobileFullWidth, ...props }: CardProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <Box
      ref={ref}
      className={clsx(
        styles['card'],
        className,
        noPadding && styles['card--no-padding'],
        noShadow && styles['card--no-shadow'],
        mobileFullWidth && styles['card--mobile-fw'],
      )}
      {...props}>
      {children}
    </Box>
  );
};

export const Card = forwardRef(CardWithRef);

export const RainbowCard = forwardRef(({ className, children, ...props }: CardProps, ref: Ref<HTMLDivElement>) => (
  <Card className={clsx(styles['rainbow-card'], className)} ref={ref} {...props}>
    <div className={styles['rainbow-content']}>{children}</div>
  </Card>
));
