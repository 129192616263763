import clsx from 'clsx';

import styles from './StyledTable.module.scss';

export type StyledTableProps = JSX.IntrinsicElements['table'] & {
  compact?: boolean;
};

export const StyledTable = ({ compact, children, className }: StyledTableProps) => (
  <table className={clsx(styles['styled-table'], compact && styles['styled-table--compact'], className)}>
    {children}
  </table>
);
