import { z } from 'zod';

import { environmentSchema } from './config.types';

const coercableToBooleanSchema = (isTrueByDefault: boolean) =>
  z.coerce
    .string()
    .transform((v): boolean => {
      if (isTrueByDefault) {
        return v !== 'false' && v !== '0';
      }
      return v === 'true' || v === '1';
    })
    .pipe(z.coerce.boolean())
    .catch(() => isTrueByDefault);

const env = environmentSchema.parse(process.env['NEXT_PUBLIC_ENV'] ?? 'dev');
const appName = z
  .enum(['app', 'admin', 'developers', 'marketing'])
  .optional()
  .parse(process.env['NEXT_PUBLIC_APP_NAME']);
const isTestEnv =
  process.env['CI'] === 'true' ||
  process.env['NODE_ENV'] === 'test' ||
  coercableToBooleanSchema(false).parse(process.env['NEXT_PUBLIC_E2E']);
const isMaintenanceMode = coercableToBooleanSchema(false).parse(process.env['NEXT_PUBLIC_MAINTENANCE_MODE']);
const isMSWLoggingEnabled = coercableToBooleanSchema(false).parse(process.env['NEXT_PUBLIC_MSW_LOGGING']);
const gaMeasurementId = z.string({ coerce: true }).optional().parse(process.env['NEXT_PUBLIC_GA_MEASUREMENT_ID']);
const disableNextErrorOverlay = coercableToBooleanSchema(false).parse(
  process.env['NEXT_PUBLIC_DISABLE_NEXT_ERROR_OVERLAY'],
);
const disableStaticPathGeneration = coercableToBooleanSchema(false).parse(
  process.env['NEXT_PUBLIC_DISABLE_STATIC_PATH_GENERATION'],
);
const e2eTimeout = z.number({ coerce: true }).optional().parse(process.env['NEXT_PUBLIC_E2E_TIMEOUT']);
const chainForSocialMigrationWarning = z
  .number({ coerce: true })
  .optional()
  .parse(process.env['NEXT_PUBLIC_SOCIAL_CHAIN_MIGRATION_WARNING']);

/**
 * Parsed environment variables.
 */
export const featureFlags = {
  env,
  appName,
  isTestEnv,
  isMaintenanceMode,
  isMSWLoggingEnabled,
  gaMeasurementId,
  disableNextErrorOverlay,
  disableStaticPathGeneration,
  e2eTimeout,
  chainForSocialMigrationWarning,
};
