import { useCallback, type MutableRefObject, type Ref } from 'react';

type PossibleRef<T> = Ref<T> | undefined;

/**
 * Set a given ref to a given value
 * This utility takes care of different types of refs: callback refs and RefObject(s)
 */
const setRef = <T>(ref: PossibleRef<T>, value: T) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    (ref as MutableRefObject<T>).current = value;
  }
};

/**
 * A utility to compose multiple refs together
 * Accepts callback refs and RefObject(s)
 */
export const composeRefs = <T>(...refs: Array<PossibleRef<T>>) => {
  return (node: T) => refs.forEach(ref => setRef(ref, node));
};

/**
 * A custom hook that composes multiple refs
 * Accepts callback refs and RefObject(s)
 */
export const useComposedRefs = <T>(...refs: Array<PossibleRef<T>>) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(composeRefs(...refs), refs);
};

export const retry = async <T>(fn: () => Promise<T>, retries = 3): Promise<T> => {
  try {
    return await fn();
  } catch (e) {
    for (let i = 0; i < retries; i++) {
      // Exponential backoff
      await new Promise(resolve => setTimeout(resolve, 1000 * 2 ** i));

      try {
        return await fn();
      } catch (e) {
        continue;
      }
    }
    throw e;
  }
};
