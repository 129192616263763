import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider } from '@chakra-ui/provider';
import type { ThemeConfig } from '@chakra-ui/react';
import type { ThemeOverride } from '@chakra-ui/theme-utils';
import { extendTheme } from '@chakra-ui/theme-utils';
import type { ReactNode } from 'react';

export const ndaoThemeConfig: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const ndaoTheme = extendTheme({
  config: ndaoThemeConfig,
  fonts: {
    heading: 'GT Haptik, Arial, Helvetica, sans-serif',
    body: 'Graphik, Arial, Helvetica, sans-serif',
    mono: "'GT Haptik', sans-serif",
  },
  semanticTokens: {
    radii: {
      md: '1rem',
      lg: '1.5rem',
    },
  },
  colors: {
    org: 'var(--color-org)',
  },
  breakpoints: {
    sm: '40rem',
    md: '71rem',
    lg: '87.5rem',
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        bgColor: '#393F49ee',
        borderRadius: '0.5rem',
        p: '0.5rem 1rem',
        fontSize: '0.875rem',
        m: '0 0.75rem',
        maxW: 'min(calc(100vw - 2rem), 300px)',
      },
      variants: {
        definition: {
          bg: 'white',
        },
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          zIndex: 20,
        },
      },
    },
  },
} satisfies ThemeOverride);

/**
 * Making this a lazy-loaded component will break SSG, DO NOT DO IT
 */
export const StylesProvider = ({ children }: { children: Array<ReactNode> | ReactNode }) => {
  return (
    <CacheProvider>
      <ChakraProvider
        theme={ndaoTheme}
        disableEnvironment
        environment={{
          // Required otherwise Chakra will inject a bad <span> tag
          getDocument: () => document,
          getWindow: () => window,
        }}>
        {children}
      </ChakraProvider>
    </CacheProvider>
  );
};

export default StylesProvider;
