// Admin types

import type { UUID } from './general';
import type { Address } from './web3';

export type EmailTemplate =
  | 'direct_donation'
  | 'grant_receipt_C'
  | 'grant_receipt_SI_C'
  | 'grant_receipt_SI_UC'
  | 'grant_receipt_SI'
  | 'grant_receipt_UC'
  | 'grant_receipt'
  | 'jcf_claim_approval'
  | 'jcf_claim_receipt'
  | 'jcf_fund_receipt'
  | 'jcf_gift_receipt'
  | 'jcf_grant_receipt'
  | 'jcf_withdrawal_receipt'
  | 'org_deployed_alert'
  | 'wire_withdrawal_receipt';

export type EmailRequest =
  | 'adminClaimEmails'
  | 'adminWithdrawalEmails'
  | 'newDonationAlerts'
  | 'newFundAlerts'
  | 'newGrantAlerts';

export type EmailRequestInputArgs = Array<string>;

export type EmailRequestInput = {
  emailType: EmailRequest;
  args: EmailRequestInputArgs;
};

export type EmailSendInput = { emailType: EmailTemplate; fields: Record<string, string> };

// eslint-disable-next-line camelcase
export type EmailFields = Array<{ template_name: EmailTemplate; body: Record<string, string> }>;

export type EmailRequestEntry = { emailRequest: EmailRequest; emailTemplates: Array<EmailTemplate> };

export type RejectGrantInput = {
  rejectionId: UUID;
  rejectionDate: number;
  rejectionTransactionHash: Address;
  grantId: UUID;
};

export type ApproveGrantInput = {
  approvalId: UUID;
  approvalDate: number;
  approvalBlockNumber: number;
  grantId: UUID;
};

export type ApproveClaimInput = {
  id: UUID;
  usingWire?: boolean;
  claimDateApproved?: number;
  claimApprovalTransactionHash?: Address;
  claimStep?: 1 | 2;
};

export type RejectClaimInput = {
  id: UUID;
  rejectionTransactionHash: Address;
};

export type PayoutInput = {
  orgId: UUID;
  orgAdminWallet?: Address;
  withdrawalAmount: number;
  txHash: Address;
  isWireWithdrawal?: boolean;
};

export type ClaimOrgInput = {
  orgId: UUID;
  claimed: boolean;
  claimDateApproved: number;
  claimOrgAdminWallet: Address;
  claimId: UUID;
  claimApprovalTransactionHash: Address;
};

export enum EndaomentAdminRole {
  EMPTY = 0,
  PAUSER,
  ACCOUNTANT,
  REVIEWER,
  FUND_FACTORY,
  ORG_FACTORY,
  ADMIN,
}

export type EndaomentAdminWallet = {
  name: string;
  address: Address;
  role: EndaomentAdminRole;
};
