import type { IconProps } from '../Icon.types';

export const NewFundIcon = ({ width = 26, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M12.0161 1.92783C12.2698 0.915015 13.7108 0.91982 13.9577 1.9343L15.0943 6.60359C15.6517 8.89328 17.4626 10.6668 19.7634 11.1762L23.8615 12.0836C24.8919 12.3118 24.912 13.7736 23.8883 14.03L19.5982 15.1047C17.3861 15.6588 15.6588 17.3861 15.1047 19.5982L13.97 24.1277C13.7172 25.1371 12.2828 25.1371 12.03 24.1277L10.8953 19.5982C10.3412 17.3861 8.61391 15.6588 6.40179 15.1047L1.87228 13.97C0.862946 13.7172 0.862943 12.2828 1.87228 12.03L6.40178 10.8953C8.61391 10.3412 10.3412 8.61391 10.8953 6.40179L12.0161 1.92783Z'
      fill={color ?? '#EA6B0E'}
      fillOpacity='0.25'
      stroke={color ?? '#EA6B0E'}
      strokeWidth='1.5'
    />
  </svg>
);
