import type { IconProps } from './Icon.types';

export const SupportIcon = ({
  width = 48,
  height = 45,
  color = 'currentColor',
  strokeColor = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 48 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 25C4.66667 27.6667 5.2751 30.5813 8.5 33C11.8333 35.5 17 35.5 18.5 35.5'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <circle cx='24' cy='25' r='19.25' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' />
    <path
      d='M45 19C45 10.8545 37.5 1 24 1C10.5 1 3 10.8545 3 19'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <path
      d='M33.9112 29.5551C32.7734 33.6419 28.8383 36.25 24 36.25C19.1617 36.25 15.2266 33.6419 14.0888 29.5551C13.9938 29.214 14.1073 28.9334 14.3568 28.7317C14.6239 28.5158 15.0422 28.4061 15.4726 28.5237C17.7813 29.1546 21.2287 29.95 24 29.95C26.7713 29.95 30.2187 29.1546 32.5274 28.5237C32.9578 28.4061 33.3761 28.5158 33.6432 28.7317C33.8927 28.9334 34.0062 29.214 33.9112 29.5551Z'
      fill={color}
      fillOpacity='0.25'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <path
      d='M21 23C21 20.7909 19.433 19 17.5 19C15.567 19 14 20.7909 14 23'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <path
      d='M34 23C34 20.7909 32.433 19 30.5 19C28.567 19 27 20.7909 27 23'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <rect
      x='0.75'
      y='18.75'
      width='3.5'
      height='10.5'
      rx='1.25'
      fill={color}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <rect
      x='43.75'
      y='18.75'
      width='3.5'
      height='10.5'
      rx='1.25'
      fill={color}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <rect
      x='12.582'
      y='31.8936'
      width='12.6235'
      height='6'
      rx='3'
      transform='rotate(2.32554 12.582 31.8936)'
      fill={color}
    />
  </svg>
);
