import type { AppProps } from 'next/app';
import Head from 'next/head';

import { config } from '@endaoment-frontend/config';
import { Footer, IntercomLoader, SuperHeader } from '@endaoment-frontend/ui/smart';

import { AppProvider } from '../components/AppProvider';
import { NavBar } from '../components/NavBar';

import '../styles/globals.scss';

const App = ({
  Component,
  pageProps,
}: AppProps<{
  dehydratedState?: string;
}>) => {
  return (
    <>
      <Head>
        <link rel='icon' href='/images/favicon.ico' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />

        {/* Disable indexing in non-production environments */}
        {config.environmentName !== 'production' && <meta name='robots' content='noindex' />}
      </Head>

      <AppProvider dehydratedState={pageProps.dehydratedState}>
        <IntercomLoader />
        <SuperHeader />
        <NavBar />
        <main className='app'>
          <Component {...pageProps} />
        </main>
        <Footer />
      </AppProvider>
    </>
  );
};

export default App;
