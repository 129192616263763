import {
  createParser,
  createSerializer,
  parseAsBoolean,
  parseAsFloat,
  parseAsInteger,
  parseAsJson,
  parseAsStringEnum,
} from 'nuqs';
import { z } from 'zod';

import type { SupportedBroker } from '@endaoment-frontend/types';
import {
  bigIntSchema,
  brokerageInfoFormSchema,
  donationRecipientSchema,
  evmTokenSchema,
  grantInstructionInputSchema,
  stockLotSchema,
  stockTickerSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

const parseAsUuid = createParser({
  parse: v => {
    try {
      return uuidSchema.parse(v);
    } catch {
      return null;
    }
  },
  serialize: v => v,
});
const parseAsBigInt = createParser({
  parse: v => {
    try {
      return bigIntSchema.parse(v);
    } catch {
      return null;
    }
  },
  serialize: v => v.toString(),
});

export const targetAllocationModalParsers = {
  isTargetAllocationModalOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  targetAllocationFundId: parseAsUuid,
} as const;

export const donationWizardParsers = {
  isDonationWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  dwMode: parseAsStringEnum(['brokerage-donation', 'credit-donation', 'erc-donation', 'grant', 'otc-donation']),
  dwRecipient: parseAsJson(donationRecipientSchema.parse),
  dwAmount: parseAsBigInt.withDefault(0n).withOptions({
    clearOnDefault: true,
  }),
  creditPledgeAmount: parseAsInteger.withDefault(0).withOptions({
    clearOnDefault: true,
  }),
  dwIncludeTaxReceipt: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
  ercToken: parseAsJson(evmTokenSchema.parse),
  grantOriginId: parseAsUuid,
  grantInstructions: parseAsJson(grantInstructionInputSchema.omit({ recommendationId: true }).parse),
  otcTokenId: parseAsInteger,
  brokerageTicker: parseAsJson(stockTickerSchema.parse),
  brokerageShares: parseAsFloat.withDefault(0).withOptions({
    clearOnDefault: true,
  }),
  brokerageLots: parseAsJson(stockLotSchema.array().parse).withDefault([]).withOptions({
    clearOnDefault: true,
  }),
  brokerageBroker: parseAsJson(brokerageInfoFormSchema.parse)
    .withDefault({
      // Even though typescript isn't happy about this, undefined is the most optimal default value for the brokerage field
      // it will be forced to be a valid value in the form
      brokerage: undefined as unknown as SupportedBroker,
      brokerageAccountNumber: '',
      brokerageContactName: '',
      brokerageEmail: '',
      brokeragePhone: '',
    })
    .withOptions({
      clearOnDefault: true,
    }),
  isRebalanceRequested: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
  recommendationId: parseAsUuid,
} as const;

export const fundWizardParsers = {
  isFundWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  fundWizardMode: parseAsStringEnum(['create', 'migrate']),
  fundWizardInitial: parseAsJson(
    z.object({
      initialDestinationFundId: uuidSchema.nullish(),
    }).parse,
  ),
} as const;

export const portfolioWizardParsers = {
  isPortfolioWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  portfolioWizardFundId: parseAsUuid,
  portfolioWizardPortfolioId: parseAsUuid,
  portfolioWizardAmount: parseAsBigInt.withDefault(0n).withOptions({
    clearOnDefault: true,
  }),
  portfolioWizardIsDeposit: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
} as const;

export const assembleSearchParamsForWizards = createSerializer({
  ...donationWizardParsers,
  ...fundWizardParsers,
  ...portfolioWizardParsers,
  ...targetAllocationModalParsers,
});
export type SearchParamsUsedByWizards = Parameters<typeof assembleSearchParamsForWizards>[1];
