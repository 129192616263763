import type { IconProps } from './Icon.types';

export const EnvelopeIcon = ({ width = 45, height = 31, color = 'currentColor', ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 45 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.125 4.7928L20.7127 15.8552C21.8105 16.5457 23.1895 16.5457 24.2873 15.8552L41.875 4.7928V4.71739C41.875 3.89547 41.2302 3.22917 40.4348 3.22917H4.56522C3.76981 3.22917 3.125 3.89547 3.125 4.71739V4.7928ZM0 4.71739C0 2.11205 2.04392 0 4.56522 0H40.4348C42.9561 0 45 2.11205 45 4.71739V25.8333C45 28.6868 42.7614 31 40 31H5C2.23858 31 0 28.6868 0 25.8333V4.71739ZM41.875 8.57315V25.8333C41.875 26.9034 41.0355 27.7708 40 27.7708H5C3.96447 27.7708 3.125 26.9034 3.125 25.8333V8.57314L19.0879 18.6135C21.1837 19.9318 23.8163 19.9318 25.9122 18.6135L41.875 8.57315Z'
      fill={color}
    />
  </svg>
);
