import { match } from 'ts-pattern';

import {
  STABLECOIN_DECIMALS,
  TIME_ONE_DAY_IN_SECONDS,
  TIME_ONE_HOUR_IN_SECONDS,
  TIME_ONE_MINUTE_IN_SECONDS,
} from '@endaoment-frontend/constants';
import {
  einDashSchema,
  einSchema,
  type EIN,
  type EINDash,
  type FundDistributionRoundSummary,
} from '@endaoment-frontend/types';

type FormatCurrencyOptions = {
  fraction: number;
  compact: boolean;
  lowercase: boolean;
};
export const formatCurrency = (input?: bigint | number | string, options?: Partial<FormatCurrencyOptions>): string => {
  const mergedOptions: FormatCurrencyOptions = {
    fraction: 2,
    compact: false,
    lowercase: false,
    ...options,
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: mergedOptions.fraction,
    minimumFractionDigits: mergedOptions.fraction,
  });

  const formatted = formatter.format(Math.trunc(Number(input || '0') * 100) / 100.0);
  const num = Number(input || '0');

  // Note: this must be done this way due to a bug in safari not considering fraction digits on "compact" `notation`.
  if (mergedOptions.compact) {
    if (num >= 1_000_000_000 || num <= -1_000_000_000) {
      return `${formatter.format(num / 1_000_000_000)}b`;
    } else if (num >= 1_000_000 || num <= -1_000_000) {
      return `${formatter.format(num / 1_000_000)}m`;
    } else if (num >= 1000 || num <= -1000) {
      return `${formatter.format(num / 1_000)}k`;
    }
  }

  const res = mergedOptions.lowercase ? formatted.toLowerCase() : formatted;
  return res === '$0' ? '$0.00' : res;
};

type FormatNumberOptions = {
  digits: number;
  compact: boolean;
  fractionDigits: number;
  stripZeros: boolean;
};
export const formatNumber = (num: number | string = 0, options?: Partial<FormatNumberOptions>) => {
  const mergedOptions: FormatNumberOptions = {
    digits: 1,
    fractionDigits: STABLECOIN_DECIMALS,
    compact: true,
    stripZeros: false,
    ...options,
  };
  const formatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: mergedOptions.digits,
    minimumSignificantDigits: mergedOptions.stripZeros ? 1 : mergedOptions.digits,
    maximumFractionDigits: mergedOptions.fractionDigits,
    minimumFractionDigits: mergedOptions.fractionDigits,
    notation: mergedOptions.compact ? 'compact' : 'standard',
    // TODO: Add when not experimental
    // trailingZeroDisplay: mergedOptions.stripZeros ? 'stripIfInteger' : 'auto',
  });

  const parts = formatter.formatToParts(typeof num === 'string' ? Number(num) : num);
  return parts.reduce(
    (acc, part) =>
      match(part.type)
        .with('compact', () => acc + part.value.toLowerCase())
        .with('fraction', () => {
          let num = part.value;
          if (mergedOptions.fractionDigits) num = part.value.slice(0, mergedOptions.fractionDigits);
          if (mergedOptions.stripZeros) num = num.replace(/0+$/, '');
          return acc + num;
        })
        .otherwise(() => acc + part.value),
    '',
  );
};

export const roundDown = (num: number, precision = 2) => {
  const factor = 10 ** precision;
  const sign = num > 0 ? 1 : -1;
  return (sign * Math.floor(num * sign * factor)) / factor;
};

type FormatDateOptions = {
  includeTime: boolean;
  dateStyle: 'full' | 'long' | 'medium' | 'short' | undefined;
  dateInUtc?: boolean;
};
export const formatDate = (dateStr: number | string, options?: Partial<FormatDateOptions>) => {
  if (typeof dateStr === 'string' && dateStr === '') return '';

  const mergedOptions: FormatDateOptions = { includeTime: false, dateStyle: 'long', ...options };
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    dateStyle: mergedOptions.dateStyle,
    timeStyle: mergedOptions.includeTime ? 'short' : undefined,
    timeZone: mergedOptions.dateInUtc ? 'UTC' : undefined,
  });
  return dateFormatter.format(typeof dateStr === 'number' ? dateStr * 1000 : new Date(dateStr));
};

export const formatShortAddress = (address?: string | null, segmentCount = 3) => {
  if (!address) return '';

  return address.length > 2 * segmentCount
    ? `${address.slice(0, segmentCount + 2)}...${address.slice(-1 * segmentCount)}`
    : address;
};

export const isValidEinFormat = (value = ''): value is EIN | EINDash => /^\d{2}-?\d{7}$/.test(value);
export const formatEin = (ein: EIN | EINDash): EINDash => einDashSchema.parse(`${ein.slice(0, 2)}-${ein.slice(2)}`);
export const normalizeEin = (ein = ''): EIN => einSchema.parse(ein.replace(/\D/gi, ''));

export const formatPhysicalAddress = (
  address?: {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
  },
  short?: boolean,
) => {
  if (!address || typeof address !== 'object') return '';

  if (short && address.country === 'USA') {
    if (!address.city && !address.state) return 'USA';
    if (!address.city) return `${address.state}, USA`;
    if (!address.state) return `${address.city}, USA`;
    return `${address.city}, ${address.state}, USA`;
  }

  if (short) {
    if (!address.city && !address.country) return '';
    if (!address.city) return address.country;
    if (!address.country) return address.city;
    return `${address.city}, ${address.country}`;
  }

  const line1 = address.line1 ?? '';
  const line2 = address.line2 ? `, ${address.line2}` : '';
  const city = address.city ? `, ${address.city}` : '';
  const comma = address.state || address.zip ? `,` : '';
  const state = address.state ? ` ${address.state}` : '';
  const zip = address.zip ? ` ${address.zip}` : '';
  return line1 + line2 + city + comma + state + zip;
};

export const capitalize = (str: string): Capitalize<string> =>
  str.replace(/^\w/, c => c.toUpperCase()) as Capitalize<string>;

export const formatStringSize = (str: string, length: number) => {
  if (str.length <= length) return str;
  return `${str.slice(0, length)}...`;
};

const divWithRemainder = (a: number, b: number): [res: number, rem: number] => [Math.max(Math.floor(a / b), 0), a % b];
export const getTimestampParts = (timestamp: number) => {
  const diff = timestamp - new Date().valueOf();

  const [days, daysRem] = divWithRemainder(diff, TIME_ONE_DAY_IN_SECONDS * 1000);
  const [hours, hoursRem] = divWithRemainder(daysRem, TIME_ONE_HOUR_IN_SECONDS * 1000);
  const [minutes, minutesRem] = divWithRemainder(hoursRem, TIME_ONE_MINUTE_IN_SECONDS * 1000);
  const [seconds] = divWithRemainder(minutesRem, 1000);
  return { days, hours, minutes, seconds };
};

export const getImpactRoundStatus = (
  summary: Pick<
    FundDistributionRoundSummary,
    'distributedTimestampUtc' | 'roundEndTimestampUtc' | 'roundStartTimestampUtc'
  >,
  now = Date.now(),
) => {
  if (summary.roundStartTimestampUtc > now) return 'Scheduled';
  else if (
    summary.roundEndTimestampUtc > now &&
    now > summary.roundEndTimestampUtc - 1 * TIME_ONE_DAY_IN_SECONDS * 1000
  )
    return 'Final Day';
  else if (
    summary.roundEndTimestampUtc > now &&
    now > summary.roundEndTimestampUtc - 3 * TIME_ONE_DAY_IN_SECONDS * 1000
  )
    return 'Ending Soon';
  else if (summary.roundEndTimestampUtc > now) return 'Live';
  else if (summary.distributedTimestampUtc) return 'Completed';
  return 'Awaiting Distribution';
};

/**
 * Formats a price impact number to be displayed as a percentage in the UI
 * @param priceImpact {number} - The price impact % as a decimal value between 0 and 1
 */
export const formatPriceImpactToPercent = (priceImpact: number | undefined): string => {
  if (!priceImpact) return '0%';

  const percent = priceImpact * 100;
  return `${formatNumber(percent, { stripZeros: true, digits: 4, fractionDigits: 2 })}%`;
};
